/* --- Application --- */
import Vue from "vue";
import VueScroll from "./plugins/vue-scroll";
import Vuelidate from 'vuelidate';
import scrollIntoViewport from "./helpers/scroll-into-viewport";
import EventBus from "./helpers/eventbus";
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import BrowserStorage from "@helpers/browser-storage";

Vue.use(VueScroll);
Vue.use(Vuelidate);

// Create the vue instance
const vm = new Vue({
    data() {
        return {
            isMounted: false,
            isFlyoutOpen: false,
            isFlyoutAnimate: false,
            showVideo: false,
            flyoutScrollbox: document.querySelector("#flyoutScrollbox") as HTMLElement,
            doc: document.documentElement as HTMLElement
        };
    },
    mounted() {
        this.flyoutScrollbox = document.querySelector("#flyoutScrollbox") as HTMLElement;

        // Helpful for css revealing effects
        setTimeout(() => { this.isMounted = true; },100);
        EventBus.$on('cookieConsent', () => {
            this.showVideo = false;
            if (BrowserStorage.getBooleanCookie('hasThirdPartyConsent')) {
                this.showVideo = true;
            }
        });

    },
    methods: {
        toggleFlyout() {
            this.isFlyoutAnimate = true;
            setTimeout(()=> {
                this.isFlyoutAnimate = false;
            }, 750);

            this.isFlyoutOpen = !this.isFlyoutOpen;

            if (this.isFlyoutOpen) {
                this.isFlyoutAnimate = true;
                this.doc.classList.add("is-not-scrollable");
                disableBodyScroll(this.flyoutScrollbox);
            } else {
                this.doc.classList.remove("is-not-scrollable");
                enableBodyScroll(this.flyoutScrollbox);
            }
        },
        scrollToTop() {
            scrollIntoViewport()(document.body);
        },
        openCookieBanner() {
            EventBus.$emit('openCookieBanner',{});
        }
    },
});

/* --- Components --- */
// If a Vue (*.vue) component exists, import only it.
// The related CSS and TS are linked into the Vue component
Vue.component("cookie-banner", () => import('./components/cookie-banner.vue'));
Vue.component("content-video", () => import('./components/content-video.vue'));
Vue.component("tag-manager", () => import('./components/tag-manager.vue'));
Vue.component("lazy-media", () => import('./components/lazy-media.vue'));
Vue.component("multi-carousel", () => import('./components/carousel.vue'));
Vue.component("google-map", () => import('./components/google-map.vue'));
Vue.component("oi-form", () => import('./components/oi-form.vue'));
Vue.component("custom-select", () => import('./components/customSelect.vue'));
Vue.component("toggle-field", () => import('./components/toggle-field.vue'));
Vue.component("content-video", () => import('./components/content-video.vue'));
Vue.component("translatable-text", () => import('./components/translatable-text.vue'));
Vue.component("entry-teaser", () => import('./components/entry-teaser.vue'));
Vue.component("network-select", () => import('./components/network-select.vue'));

// Vue.config.errorHandler = function(err) { console.log("errorHandler", err) }

// Connect the Vue instance to the whole <main id="view"> container
// Avoid to use the standard DOM API as a virtual-dom will handle it
vm.$mount("#view");
